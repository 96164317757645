import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { MachineVerificationShelfMachineQuery } from './machine-verification-shelf.gql-gen';
import { IonButton, IonIcon, IonText } from '@ionic/angular/standalone';

@Component({
  selector: 'app-machine-verification-shelf',
  standalone: true,
  imports: [IonButton, IonIcon, IonText],
  templateUrl: './machine-verification-shelf.component.html',
  styleUrl: './machine-verification-shelf.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MachineVerificationShelfComponent {
  @Input({ required: true }) machine?: MachineVerificationShelfMachineQuery['machine'];
}
