@if (uiData.status === "available") {
  <ion-item color="rcgreen" class="availability-item" lines="none">
    <ion-label class="ion-no-margin">Свободна</ion-label>
    <ion-text>{{ uiData.starts }}</ion-text>
  </ion-item>
}
@if (uiData.status === "busy") {
  <ion-item color="rcred" class="availability-item" lines="none">
    <ion-label class="ion-no-margin">Занята</ion-label>
    <ion-text>{{ uiData.ends }}</ion-text>
  </ion-item>
}
@if (uiData.status === "unknown") {
  <ion-item color="light" class="availability-item" lines="none">
    <ion-label class="ion-no-margin">Без статуса</ion-label>
  </ion-item>
}
