@if (machine?.verificationStatus === "approved") {
  <ion-button shape="round" color="rcgreen" size="small">
    <ion-icon slot="start" name="checkmark-circle"></ion-icon>
    <ion-text>Владение подтверждено</ion-text>
  </ion-button>
}
@if (machine?.verificationStatus === "none" || machine?.verificationStatus === "declined") {
  <ion-button shape="round" size="small" color="rcred">
    <ion-icon slot="start" name="warning"></ion-icon>
    <ion-text>Владение не подтверждено</ion-text>
  </ion-button>
}
@if (machine?.verificationStatus === "pending") {
  <ion-button shape="round" size="small" color="rcyellow">
    <ion-icon slot="start" name="warning"></ion-icon>
    <ion-text>Владение на рассмотрении</ion-text>
  </ion-button>
}
